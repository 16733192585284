import React from 'react';
import { Col, Row, Typography } from 'antd';
import { COLORS } from '../../../const/colors';
import styled from 'styled-components';

type ReasonPartialProps = {
  Icon: React.ReactNode;
  title: string;
  text: string;
};

const StyledCol = styled(Col)`
  width: 30%;

  @media screen and (max-width: 1024px) {
    width: 45%;
  }

  @media screen and (max-width: 768px) {
    width: 75%;
  }
`;

const ReasonPartial = ({ title, text, Icon }: ReasonPartialProps) => {
  return (
    <StyledCol style={{ padding: 16 }}>
      <Row justify="center">{Icon}</Row>
      <Row style={{ alignItems: 'center', flexDirection: 'column' }}>
        <Typography.Title
          level={4}
          style={{
            color: COLORS.blue,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography.Title>
        <Typography.Text style={{ color: COLORS.blue, textAlign: 'center' }}>
          {text}
        </Typography.Text>
      </Row>
    </StyledCol>
  );
};

export default ReasonPartial;
