import React from 'react';
import { Button, Col, Row as DefaultRow, Typography } from 'antd';
import styled from 'styled-components';
import BannerSvg from '../../assets/banner.png';
import { openWhatsapp } from '../../core/apis/whatsapp';

const Row = styled(DefaultRow)`
  width: 100%;
  padding: 79px;
  margin: 6vw auto 0;
  justify-content: center;
  gap: 8px;
  position: relative;
  background-image: url(${BannerSvg});
  background-size: cover;
  background-position: center;

  .ant-col {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    @media screen and (max-width: 992px) {
      width: 95%;
    }
  }
`;

const StyledButton = styled(Button)`
  background: transparent;
  border: 2px solid white;
  border-radius: 0;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 20px;
  :hover {
    background: #f9f9f9 !important;
    color: #012340 !important;
  }
`;

const Index = () => {
  return (
    <Row id="banner">
      <Col style={{}}>
        <Typography.Title
          level={2}
          style={{ color: '#f9f9f9', textAlign: 'center' }}
        >
          Pronto para fazer uma parceria de sucesso?
        </Typography.Title>
        <StyledButton
          onClick={() =>
            openWhatsapp(
              'Olá, gostaria de obter mais informações sobre seus Serviços!'
            )
          }
        >
          Sim, vamos começar
        </StyledButton>
      </Col>
    </Row>
  );
};

export default Index;
