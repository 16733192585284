import React from 'react';
import { Col, Row as DefaultRow, Typography, Image } from 'antd';
import styled from 'styled-components';
import SoftwareWeb from '../../assets/software-web.svg';
import AppPwa from '../../assets/app-pwa.svg';
import Multiple from '../../assets/multiple.svg';

const Row = styled(DefaultRow)`
  width: 75%;
  margin: 16px auto 0;
  justify-content: center;
  gap: 8px;
`;

const Index = () => {
  return (
    <Row id="services">
      <Typography.Title>Nossos Serviços</Typography.Title>
      <DefaultRow gutter={64}>
        <Col sm={24} md={8}>
          <Image src={SoftwareWeb} />
        </Col>
        <Col sm={24} md={8}>
          <Image src={AppPwa} />
        </Col>
        <Col sm={24} md={8}>
          <Image src={Multiple} />
        </Col>
      </DefaultRow>
    </Row>
  );
};

export default Index;
