import {
  Button as DefaultButton,
  Col as DefaultCol,
  Row as DefaultRow,
  Typography,
} from 'antd';
import React from 'react';
import { ReactComponent as WelcomeSvg } from '../../assets/welcome.svg';
import styled from 'styled-components';
import { openWhatsapp } from '../../core/apis/whatsapp';

const Row = styled(DefaultRow)`
  height: calc(70vh);
  @media screen and (max-width: 768px) {
    height: auto;
  }
`;
const Col = styled(DefaultCol)`
  height: 100%;
  padding-top: 16px !important;
  display: flex;
  flex-direction: column;
  > .ant-row {
    width: 57.5%;
  }
  @media screen and (max-width: 1200px) {
    align-items: center !important;
    padding-top: 0 !important;
    > .ant-row {
      width: 75%;
    }
    > svg {
      width: 80% !important;
      @media screen and (max-width: 575px) {
        margin-top: -15vh;
      }
    }
  }
  > svg {
    width: 65%;
  }
`;
const Button = styled(DefaultButton)`
  width: 47.5%;
  height: 42px;
  border-radius: 3px !important;
  background-color: #012340;
  color: white;
  font-size: 14px;
  font-weight: 550;

  @media screen and (max-width: 575px) {
    width: 100%;
    margin: 8px 0;
  }
`;

const Welcome = () => {
  return (
    <Row id="company">
      <Col
        sm={24}
        md={12}
        xl={12}
        style={{ alignItems: 'flex-end', justifyContent: 'center' }}
      >
        <DefaultRow>
          <Typography.Title level={1} style={{ fontWeight: 'bold' }}>
            Faça com que <br />
            Seus sonhos
            <br />
            Se tornem realidade
          </Typography.Title>
        </DefaultRow>
        <DefaultRow>
          <Typography.Text style={{ width: '85%' }}>
            Conte conosco no desenvolvimento do seu software. Estamos
            atualizados com o mercado e as tecnologias utilizadas, trazendo
            sempre o melhor para nosso cliente.
          </Typography.Text>
        </DefaultRow>
        <DefaultRow>
          <DefaultRow
            style={{
              width: '100%',
              justifyContent: 'space-between',
              marginTop: 16,
            }}
          >
            <Button
              type="primary"
              onClick={() =>
                openWhatsapp(
                  'Olá, gostaria de obter mais informações sobre seus Serviços!'
                )
              }
            >
              Entrar em contato
            </Button>
            <Button
              onClick={() =>
                openWhatsapp('Olá, gostaria de solicitar um orçamento!')
              }
            >
              Solicitar um orçamento
            </Button>
          </DefaultRow>
        </DefaultRow>
      </Col>
      <Col sm={24} md={12} xl={12}>
        <WelcomeSvg />
      </Col>
    </Row>
  );
};

export default Welcome;
