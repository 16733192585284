import React, { useEffect, useState } from 'react';
import { Col, Row, Typography, Image } from 'antd';
import WhiteLogo from '../../assets/white-logo.svg';
import styled from 'styled-components';

const Footer = styled.footer`
  margin-top: 64px;
  background-color: #012340;
`;

const Index = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Footer>
      <Row justify="space-around" style={{ padding: 64, minHeight: windowWidth < 768 ? '60vh' : '40vh' }}>
        <Col
          sm={8}
          md={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Image src={WhiteLogo} style={{ fill: 'white' }} />
        </Col>
        <Col
          sm={8}
          md={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography.Text style={{ color: 'white' }}>
            MATRIZ - ESPÍRITO SANTO
            <br /> Av. Champagnat, 635
            <br /> Praia da Costa - Vila Velha/ES
            <br /> CEP: 29100-011 (27) 3062-2230
          </Typography.Text>
        </Col>
        <Col
          sm={8}
          md={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography.Text style={{ color: 'white' }}>
            MATRIZ - ESPÍRITO SANTO
            <br /> Av. Champagnat, 635
            <br /> Praia da Costa - Vila Velha/ES
            <br /> CEP: 29100-011 (27) 3062-2230
          </Typography.Text>
        </Col>
      </Row>
      <Row
        style={{
          borderTop: '1px solid #458DC4',
          justifyContent: 'center',
          padding: 5,
        }}
      >
        <Typography.Title style={{ color: 'white', margin: 0 }} level={5}>
          © CodeverseX. Todos os direitos reservados.
        </Typography.Title>
      </Row>
    </Footer>
  );
};

export default Index;
