import React, { useState } from 'react';
import { Menu } from 'antd';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import styled from 'styled-components';

const { Item } = Menu;

const Header = styled.header`
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;

  @media screen and (max-width: 768px) {
    justify-content: space-between;
    svg {
      width: auto;
    }
  }

  svg {
    width: 45%;
  }

  .ant-menu {
    width: 50%;
    background-color: transparent;
    border: 0;
    @media screen and (max-width: 768px) {
      width: 15%;
    }

    .anticon {
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

interface MenuItem {
  title: string;
  key: string;
}

const items: MenuItem[] = [
  {
    title: 'A Empresa',
    key: 'company',
  },
  {
    title: 'Parceiros',
    key: 'partners',
  },
  {
    title: 'Serviços',
    key: 'services',
  },
  {
    title: 'Valores',
    key: 'reasons',
  },
  {
    title: 'Contratar',
    key: 'banner',
  },
];

const App: React.FC = () => {
  const [current, setCurrent] = useState<string>('company');

  const handleClick = (e: any) => {
    setCurrent(e.key);
  };

  return (
    <Header>
      <Logo />
      <Menu mode="horizontal" onClick={handleClick} selectedKeys={[current]}>
        {items.map((item) => (
          <Item key={item.key}>
            <a href={`#${item.key}`}>{item.title}</a>
          </Item>
        ))}
      </Menu>
    </Header>
  );
};

export default App;
