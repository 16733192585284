import React from 'react';
import Navbar from '../components/navbar';
import Welcome from '../components/welcome';
import Partners from '../components/partners';
import Services from '../components/services';
import Footer from '../components/footer';
import Reasons from '../components/reasons';
import Banner from "../components/banner";

function Home() {
  return (
    <>
      <Navbar />
      <Welcome />
      <Partners />
      <Services />
      <Reasons />
      <Banner />
      <Footer />
    </>
  );
}

export default Home;
