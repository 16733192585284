import React from 'react';
import { Row as DefaultRow, Typography } from 'antd';
import styled from 'styled-components';
import ReasonPartial from './partials/ReasonPartial';
import { FiUserCheck, FiMonitor, FiThumbsUp } from 'react-icons/fi';
import { GiCoinflip, GiBriefcase } from 'react-icons/gi';
import { COLORS } from '../../const/colors';

const Row = styled(DefaultRow)`
  width: 75%;
  margin: 64px auto 0;
  justify-content: center;
  gap: 8px;
`;

const Index = () => {
  return (
    <Row id='reasons'>
      <Typography.Title
        level={1}
        style={{ fontWeight: 'bold', color: '#323E6D' }}
      >
        Por quê contratar a CodeverseX?
      </Typography.Title>
      <DefaultRow
        style={{
          width: '100%',
          gap: 64,
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <ReasonPartial
          title="Profissionais Experientes"
          text="Trabalhe com profissionais que tem mais de 2 anos de mercado."
          Icon={<FiUserCheck size={39} color={COLORS.blue} />}
        />
        <ReasonPartial
          title="Custo-Beneficio"
          text="Certeza de pagar preços justos por projetos com um padrão de qualidade excelente."
          Icon={<GiCoinflip size={39} color={COLORS.blue} />}
        />
        <ReasonPartial
          title="Tecnologias Atuais"
          text="Tenha no seu projeto as tecnologias mais atuais e demandadas do mercado, trazendo inovação e qualidade."
          Icon={<FiMonitor size={39} color={COLORS.blue} />}
        />
        <ReasonPartial
          title="Ética e Respeito"
          text="Una-se com uma equipe que valoriza pilares de ética e respeito."
          Icon={<FiThumbsUp size={39} color={COLORS.blue} />}
        />
        <ReasonPartial
          title="Profissionalismo"
          text="Atue com profissionais que cumprem as datas e horários previstos."
          Icon={<GiBriefcase size={39} color={COLORS.blue} />}
        />
      </DefaultRow>
    </Row>
  );
};

export default Index;
