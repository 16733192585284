import React from 'react';
import { Row as DefaultRow, Typography, Image } from 'antd';
import styled from 'styled-components';
import NorthPub from '../../assets/north-pub.png';
import { Link } from 'react-router-dom';

const Row = styled(DefaultRow)`
  width: 75%;
  margin: auto;
  justify-content: center;
  gap: 8px;

  @media screen and (max-width: 575px) {
    margin-top: -15vh;
  }
`;

const Index = () => {
  return (
    <Row id="partners">
      <Typography.Title level={1} style={{ fontWeight: 'bold' }}>
        Quem confia na gente
      </Typography.Title>
      <Row style={{ marginTop: 16 }}>
        <Link to="https://northpub.com.br/" target="_blank">
          <Image src={NorthPub} />
        </Link>
      </Row>
    </Row>
  );
};

export default Index;
